import HomeFooter from '@/Home/HomeFooter.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { AuthPopups } from '@/Authentication/authentication.const';
import TrustPublication from '@/components/TrustPublication.vue';
import { computed, defineComponent, onMounted, onUnmounted, watch, ref, getCurrentInstance } from '@vue/composition-api';
import useStructuredData from '@/shared/composables/useStructuredData';
import { SITE_URL } from '@/App.vue';
import PageFAQ from '@/components/PageFAQ.vue';
import { useGetHomePageContentQuery } from '@/generated-types/graphql.types';
import { useStoryblokBridge } from '@/shared/composables/useStoryblokBridge';
export default defineComponent({
    components: {
        PageFAQ,
        TrustPublication,
        HomeFooter
    },
    setup(_, context) {
        const instance = getCurrentInstance();
        const { root } = context;
        const { result, refetch } = useGetHomePageContentQuery({ enabled: true });
        const storyBase = ref(null);
        const story = computed({
            get: () => storyBase.value,
            set: (value) => (storyBase.value = value)
        });
        watch(() => result.value, () => {
            if (result.value) {
                storyBase.value = JSON.parse(result.value.get_home_page_cms_content.story).story;
            }
        }, { immediate: true });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const schemaOrgInfo = computed(() => {
            // https://schema.org
            // https://www.searchenginejournal.com/google-site-names/467892/
            return {
                '@context': 'https://schema.org',
                '@graph': [
                    {
                        '@type': 'Organization',
                        name: 'We Meet B.V.',
                        url: `${SITE_URL}${root.$router.currentRoute.path}`,
                        logo: 'https://media.wemeet.nl/gallery/assets/home/logo_8_new_Y9FP-Fb5m.png',
                        contactPoint: {
                            '@type': 'ContactPoint',
                            telephone: '+31 20 204 4544',
                            contactType: 'Customer service'
                        },
                        sameAs: [
                            'https://www.instagram.com/wemeet.nl/',
                            'https://www.facebook.com/WeMeet.nl/',
                            'https://linkedin.com/company/wemeet-nl',
                            'https://twitter.com/we_meet_NL'
                        ]
                    },
                    {
                        '@type': 'WebSite',
                        name: 'We Meet',
                        url: SITE_URL,
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: `${SITE_URL}/search?q={search_term_string}`,
                            'query-input': 'required name=search_term_string'
                        }
                    }
                ]
            };
        });
        const makeAuthRedirect = () => {
            const query = root.$route.query;
            const state = query?.state;
            if (state && typeof state === 'string') {
                query.authPopup = AuthPopups[state];
            }
            globalThis.$router.replace({
                name: globalThis.$i18n.locale !== 'nl'
                    ? `en-${ROUTE_LIST_NAMES.MAIN}`
                    : ROUTE_LIST_NAMES.MAIN,
                query: {
                    ...query,
                    token: null,
                    email_token: null,
                    ['verify-email']: null,
                    ['verify-phone-with-link']: null,
                    redirect_url: null
                }
            });
        };
        /**
         * Watch for locale change and refetch the home page content
         */
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        /**
         * Update structured data to the page when FAQ is loaded
         * @param structuredData
         */
        const injectData = (structuredData) => {
            const data = schemaOrgInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        };
        const faqQuestions = computed(() => [
            {
                question: root.$t('home.faq.question_1.title'),
                answer: root.$t('home.faq.question_1.answer')
            },
            {
                question: root.$t('home.faq.question_2.title'),
                answer: root.$t('home.faq.question_2.answer')
            },
            {
                question: root.$t('home.faq.question_3.title'),
                answer: root.$t('home.faq.question_3.answer')
            },
            {
                question: root.$t('home.faq.question_4.title'),
                answer: root.$t('home.faq.question_4.answer')
            }
        ]);
        if (window.location.search.includes('_storyblok')) {
            // load the bridge only inside of Storyblok Editor
            useStoryblokBridge(story);
            instance.$on('storyPublished', refetch);
        }
        onMounted(() => {
            injectStructuredData(schemaOrgInfo.value);
            makeAuthRedirect();
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            story,
            faqQuestions,
            injectData
        };
    }
});
